import { Routes, Route, useNavigate } from 'react-router-dom';
import axios from 'axios';
import SignIn from "./views/Auth/SignIn";
import SignUp from "./views/Auth/SignUp";
import CreateSolutionPage from "./layouts/CreateSolutionPage.js";
import CreateSolutionResultPage from "./layouts/CreateSolutionResultPage.js";
import ModifySolutionPage from "./layouts/ModifySolutionPage";
import ModifySolutionPageResult from "./layouts/ModifySolutionPageResult";
import SelectPassagePage from "./layouts/SelectPassagePage.js";
import CreateQuestionPage from "./layouts/CreateQuestionPage.js";
import ShowPassageListPage from "./layouts/ShowPassageListPage.js";
import ProblemBankPage from "./layouts/ProblemBankPage.js";
import ProblemBankResultPage from "./layouts/ProblemBankResultPage.js";
import WritePassagePage from "./layouts/WritePassagePage.js"
import WorkbookListPage from "./layouts/WorkbookListPage.js"
import StudentManagePage from "./layouts/StudentManagePage.js"
import WorkbookDetailPage from "./layouts/WorkbookDetailPage.js"
import CreatePassageAIPage from "./layouts/CreatePassageAIPage.js"
import ManageStudentPage from "./layouts/ManageStudentPage.js"
import AssignTaskPage from "./layouts/AssignTaskPage.js"
import React, { useEffect, useState } from 'react';

import Pages from "./layouts/Auth.js"
import Dashboard from "./layouts/Admin.js"
import GradeQuestionPage from "./layouts/GradeQuestionPage.js";
import ModifyProblemPage from "./layouts/ModifyProblemPage.js";
import ProblemBankPageStu from "./layouts/ProblemBankPageStu.js";
import ProblemBankResultPageStu from "./layouts/ProblemBankResultPageStu.js";
import { Spinner, Center } from "@chakra-ui/react"; // Import Chakra UI Spinner and Center

function App() {
  const [role, setRole] = useState(null); // 'teacher', 'student', or null
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const verifyAndRefreshToken = async () => {
      try {
        let token = localStorage.getItem('token');
        if (!token) {
          return;
        }

        // Verify the token
        try {
          const response = await axios.post(`${process.env.REACT_APP_BACKEND_API_ENDPOINT}/verify/`, {
            token: token
          });
          if (response.data.code === 'token_not_valid') {
            throw new Error('Token expired');
          }
        } catch (error) {
          // Token is invalid or expired, try to refresh
          const refreshToken = localStorage.getItem('refresh_token');
          if (!refreshToken) {
            setLoading(false);
            return;
          }

          const refreshResponse = await axios.post(`${process.env.REACT_APP_BACKEND_API_ENDPOINT}/refresh/`, {
            refresh: refreshToken
          });
          token = refreshResponse.data.access;
          localStorage.setItem('token', token);
        }

        // Fetch user role
        const roleResponse = await axios.get(`${process.env.REACT_APP_BACKEND_API_ENDPOINT}/user/verify-role`, {
          headers: {
            Authorization: `JWT ${token}`,
          },
        });
        setRole(roleResponse.data.role);
      } catch (error) {
        console.error('Error verifying role or refreshing token', error);
      } finally {
        setLoading(false);
      }
    };

    verifyAndRefreshToken();
  }, [navigate]);

  if (loading) {
    return (
      <Center height="100vh">
        <Spinner 
          thickness="4px"
          color='blue.500'
          width="100px"
          height="100px"
        />
      </Center>
    );
  }

  return (
    <div>
      {window.localStorage.getItem("token") ?  
        role === 'teacher' ? 
          <Routes>
            <Route path="/" element={<StudentManagePage />} />
            <Route path="student-manage/" element={<StudentManagePage />} />        
            <Route path="grade-question/" element={<GradeQuestionPage />} />        
            <Route path="create-solution/" element={<CreateSolutionPage />} />        
            <Route path="assign-task/" element={<AssignTaskPage />} />
            <Route path="manage-student/" element={<ManageStudentPage />} />
            <Route path="select-passage/" element={<SelectPassagePage />} />        
            <Route path="create-solution-result/" element={<CreateSolutionResultPage />} />
            <Route path="modify-question/" element={<ModifyProblemPage />} />
            <Route path="modify-problem/" element={<ModifySolutionPage />} />
            <Route path="modify-problem-result/" element={<ModifySolutionPageResult />} />
            <Route path="problem-bank/" element={<ProblemBankPage />} />
            <Route path="problem-bank-result/" element={<ProblemBankResultPage />} />
            <Route path="passage-list/" element={<ShowPassageListPage />} />
            <Route path="create-question/" element={<CreateQuestionPage />} />
            <Route path="write-passage/" element={<WritePassagePage />} />
            <Route path="workbook-list/" element={<WorkbookListPage />} />
            <Route path="workbook-detail/" element={<WorkbookDetailPage />} />
            <Route path="passage-ai-create/" element={<CreatePassageAIPage />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/signin" element={<SignIn />} />
          </Routes> :
          <Routes>
            {/* student 에 대한 Route */}
            <Route path="/" element={<ProblemBankPageStu />} />        
            <Route path="problem-bank-stu/" element={<ProblemBankPageStu />} />
            <Route path="problem-bank-result-stu/" element={<ProblemBankResultPageStu />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/signin" element={<SignIn />} />
          </Routes>
        :
        <Routes>
          <Route path="/" element={<SignIn />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/signin" element={<SignIn />} />
        </Routes>
      }
    </div>
  );
}

export default App;
