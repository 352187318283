import {
  Flex,
  Box,
  Heading,
  Text,
  VStack,
  SimpleGrid,
  Grid,
  GridItem,
  useToast,
  Button,
  HStack,
  Badge,
} from "@chakra-ui/react";
import { useLocation } from 'react-router-dom';
import React from 'react';
import axios from 'axios';

export default function ProblemBankResultDashboard() {
  const location = useLocation();
  const { workbook, passages, student } = location.state || { workbook: { questions: [] }, passages: {}, student: null };
  const toast = useToast();
  const [questions, setQuestions] = React.useState(workbook.questions);
  const [isLoading, setIsLoading] = React.useState(false);

  const handleTransformQuestions = async () => {
    setIsLoading(true);
    const token = localStorage.getItem('token');

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_API_ENDPOINT}/api/transform_questions/`,  
        { workbook_id: workbook.id },
        {
          headers: {
            Authorization: `JWT ${token}`,
          },
        }
      );

      const transformedQuestions = response.data;
      setQuestions([...questions, ...transformedQuestions]);

      toast({
        title: "문제 변형 성공",
        description: "문제가 성공적으로 변형되었습니다.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Error transforming questions", error);
      toast({
        title: "문제 변형 실패",
        description: "문제 변형 중 오류가 발생했습니다.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Flex flexDirection="column" pt={{ base: "120px", md: "75px" }}>
      <Grid templateColumns={{ md: "1fr", lg: "1fr" }} templateRows={{ md: "1fr auto", lg: "1fr" }} my="26px" gap="24px">
        <Box bg="white" borderWidth="1px" borderRadius="lg" p={5}>
          <Heading size="md" mb={5}>{workbook.title}</Heading>
          
          <Button 
            colorScheme="teal" 
            onClick={handleTransformQuestions} 
            mb={5} 
            isLoading={isLoading}
            loadingText="변형 중..."
          >
            문제 변형하기
          </Button>

          {questions.length > 0 ? (
            <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10} mt={5}>
              {questions.map((question, index) => (
                <GridItem key={index} colSpan={1}>
                  <Box 
                    borderWidth="1px" 
                    borderRadius="lg" 
                    p={10} 
                    mb={4} 
                    position="relative" 
                    bg={question.iteration > 0 ? "teal.50" : "gray.50"} // 변형된 문제와 원본 문제 구분
                    borderColor={question.iteration > 0 ? "teal.500" : "gray.500"}
                  >
                    <Badge 
                      colorScheme={question.iteration > 0 ? "teal" : "gray"} 
                      position="absolute" 
                      top={2} 
                      right={2}
                    >
                      {question.iteration > 0 ? "변형된 문제" : "원본 문제"}
                    </Badge>
                    <div dangerouslySetInnerHTML={{ __html: question.content }}></div>
                    {question.choice_1 && (
                      <VStack align="start" mt={3} spacing={2}>
                        <HStack>
                          <Text fontWeight="bold">1.</Text>
                          <div dangerouslySetInnerHTML={{ __html: question.choice_1 }}></div>
                        </HStack>
                        <HStack>
                          <Text fontWeight="bold">2.</Text>
                          <div dangerouslySetInnerHTML={{ __html: question.choice_2 }}></div>
                        </HStack>
                        <HStack>
                          <Text fontWeight="bold">3.</Text>
                          <div dangerouslySetInnerHTML={{ __html: question.choice_3 }}></div>
                        </HStack>
                        <HStack>
                          <Text fontWeight="bold">4.</Text>
                          <div dangerouslySetInnerHTML={{ __html: question.choice_4 }}></div>
                        </HStack>
                        <HStack>
                          <Text fontWeight="bold">5.</Text>
                          <div dangerouslySetInnerHTML={{ __html: question.choice_5 }}></div>
                        </HStack>
                      </VStack>
                    )}
                  </Box>
                </GridItem>
              ))}
            </SimpleGrid>
          ) : (
            <Text textAlign="center">관련된 문제가 없습니다.</Text>
          )}
        </Box>
      </Grid>
    </Flex>
  );
}
