import React, { FC, useEffect, useState } from "react";
import {
    Flex,
    Grid,
    Image,
    SimpleGrid,
    useColorModeValue,
    IconButton,
    ButtonGroup,
    SkeletonCircle,
    SkeletonText,
    Box,
  } from "@chakra-ui/react";
  import axios from "axios";
import { MdBookmark, MdBookmarkBorder, MdRestartAlt} from "react-icons/md";

import { Card, CardHeader, CardBody, CardFooter } from '@chakra-ui/react'
import { Heading } from '@chakra-ui/react'
import { Text } from '@chakra-ui/react'
import { Divider } from '@chakra-ui/react'

import { useLocation } from 'react-router-dom';
import DOMPurify from 'dompurify';
import { MathComponent } from "mathjax-react";

interface IResponseObject {
    content: string;
}

const ModifyProblemResult: FC = () => {
    const [response, setResponse] = useState<IResponseObject>({ content: "" });
    const location = useLocation();
    const [complete, setComplete] = useState(false);
    const [isBookmark, setBookmark] = useState(false);

    console.log(location.state.problem_id)
    console.log(location.state.problem_id)
    console.log(location.state.html_text)

    function clickBookmark(){
        setBookmark((prevbookmark) => { 
            if (prevbookmark) {
                let url = `${process.env.REACT_APP_BACKEND_API_ENDPOINT}/solution_generator/bookmark_problem/?problem_id=${location.state.problem_id}&bookmark=${prevbookmark}`;
                axios.patch(url)
                console.log(prevbookmark)
                return false

            }
            else {
                let url = `${process.env.REACT_APP_BACKEND_API_ENDPOINT}/solution_generator/bookmark_problem/?problem_id=${location.state.problem_id}&bookmark=${prevbookmark}`;
                axios.patch(url)
                console.log(prevbookmark)
                return true
            }
          })
    }


    useEffect(() => {
        const eventSource = new EventSource(`${process.env.REACT_APP_BACKEND_API_ENDPOINT}/solution_generator/modify_solution/?problem_id=${location.state.problem_id}`);

        eventSource.onmessage = (event) => {
            const responseObject = event.data;
            console.log(event.data);

            setResponse((prev: IResponseObject) => {
                console.log(event.data);
                const responseObjectContent = responseObject || "";
                const safeHTML = DOMPurify.sanitize(responseObjectContent);

                const combinedObject = {
                    content: safeHTML,
                };
                return combinedObject;
            });

        };

        eventSource.onerror = (error) => {
            console.log("Error with SSE connection:", error);
            eventSource.close(); // Also close on error
            setComplete((prevcompelete) => { return true})

        };

        // This will close the connection when the component unmounts
        return () => {
            eventSource.close();
            setComplete((prevcompelete) => { return true})

        };
    }, [location.state.problem_id]); // Add dependency to useEffect

    return (
  
        <Flex flexDirection="column" pt={{ base: "120px", md: "75px" }}>


        <Grid
          templateColumns={{ md: "1fr", lg: "1fr" }}
          templateRows={{ md: "1fr auto", lg: "1fr" }}
          my="26px"
          gap="24px"
        >
        <div>
            <div>

            <Card align='center'>
                <CardHeader>
                    <Heading size='md'> 원본 문제 </Heading>
                    </CardHeader>
                    <CardBody>
                        <Text><div dangerouslySetInnerHTML={ { __html: location.state.description } } /></Text>
                </CardBody>
                <CardFooter>
                </CardFooter>
                </Card>
                    <Divider orientation='horizontal' />                   
                    
                        <Card align='center'>
                        <CardHeader>
                            <Heading size='md'> 변형 문제 & 해설지</Heading>
                            </CardHeader>
                            <CardBody>
                            {response.content ? 
                                <Text> <div dangerouslySetInnerHTML={ { __html: response.content } }/></Text>
                                :  
                                <SkeletonCircle size='5' />
                              }
                        </CardBody>
                        <CardFooter>
                        <ButtonGroup gap='2'> 
       <IconButton 
       isDisabled={!complete}
       onClick={clickBookmark} aria-label='Book Mark' icon={isBookmark ? <MdBookmark />: <MdBookmarkBorder/>}  />
       <IconButton 
       isDisabled={!complete} 
       aria-label='Repeat' icon={<MdRestartAlt />} />
        </ButtonGroup>
                        </CardFooter>
                        </Card>
                   

            </div>
        </div>
        </Grid>
    </Flex>
    );
};

export default ModifyProblemResult;
