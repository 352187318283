import React, { useState } from 'react';
import {
  Flex,
  Grid,
  Box,
  Text,
  VStack,
  Center,
  Button,
  Heading,
  Icon,
  useToast,
} from "@chakra-ui/react";
import { Card, CardBody } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { FaBook, FaRobot, FaPen, FaUpload } from 'react-icons/fa';
import axios from 'axios';

export default function CreateSolutionDashboard() {
  const [file, setFile] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();
  const toast = useToast();

  const handleSelect = () => {
    navigate('/select-passage');
  };

  const handleWritePassage = () => {
    navigate('/write-passage');
  };

  const handleAIPassage = () => {
    navigate('/passage-ai-create');
  };

  const handleChange = (file) => {
    setFile(file);
  };

  const handleOCRPassage = () => {
    navigate('/modify-problem');
  };

  const handleSubmit = (e) => {
    setLoading(true);
    e.preventDefault();
    console.log(file);
    let form_data = new FormData();
    form_data.append('image', file);
    form_data.append('title', file.name);
    let url = `${process.env.REACT_APP_BACKEND_API_ENDPOINT}/solution_generator/problem/`;
    axios.post(url, form_data, {
      headers: {
        'content-type': 'multipart/form-data'
      }
    })
      .then(res => {
        console.log(res.data);
        navigate('/create-solution-result', {
          state: { problem_id: res.data.id, html_text: res.data.html_text }
        });
      })
      .catch(err => console.log(err));
  };

  return (
    <Flex flexDirection="column" pt={{ base: "120px", md: "75px" }}>
      <Grid
        templateColumns={{ md: "1fr", lg: "1fr" }}
        templateRows={{ md: "1fr auto", lg: "1fr" }}
        my="26px"
        gap="24px"
      >
        <Box bg="white" borderWidth="1px" borderRadius="lg" p={3}>
          <Box p={3}>
            <Center>
              <Heading size='lg'>문제집 생성</Heading>
            </Center>
          </Box>
          <VStack spacing={6} mt={4}>
            <Card variant='outline' width="60%" height="100px">
              <CardBody display="flex" justifyContent="center" alignItems="center">
                <Button leftIcon={<Icon as={FaBook} />} colorScheme='teal' variant='ghost' onClick={handleSelect} size='lg'>
                  문제 은행
                </Button>
              </CardBody>
            </Card>
            {/* <Card variant="outline" width="60%" height="100px">
              <CardBody display="flex" justifyContent="center" alignItems="center">
                <Button leftIcon={<Icon as={FaRobot} />} colorScheme='teal' variant='ghost' onClick={handleAIPassage} size='lg'>
                  AI 지문 생성
                </Button>
              </CardBody>
            </Card> */}
            <Card variant="outline" width="60%" height="100px">
              <CardBody display="flex" justifyContent="center" alignItems="center">
                <Button leftIcon={<Icon as={FaPen} />} colorScheme='teal' variant='ghost' onClick={handleWritePassage} size='lg'>
                  직접 문제집 입력
                </Button>
              </CardBody>
            </Card>
            <Card variant="outline" width="60%" height="100px">
              <CardBody display="flex" justifyContent="center" alignItems="center">
                <Button leftIcon={<Icon as={FaUpload} />} colorScheme='teal' variant='ghost' onClick={handleOCRPassage} size='lg'>
                  문제집 업로드
                </Button>
              </CardBody>
            </Card>
          </VStack>
        </Box>
      </Grid>
    </Flex>
  );
}
