import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useToast,
  ChakraProvider,
  Grid,
  ButtonGroup,
  Card,
  CardBody,
  Stack,
  Text,
} from "@chakra-ui/react";
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';


export default function  AssignTaskDashboard(){
    const [tasks, setTasks] = useState([]);
    const toast = useToast();
    const location = useLocation();
    const studentID = location.state?.studentID; // Optional chaining to safely access studentID
    const navigate = useNavigate();

    useEffect(() => {
      console.log(studentID);

      fetchTasks();
    }, []);
  
    const fetchTasks = async () => {
      const token = localStorage.getItem('token');
    //   const studentID = location.state.studentID

      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_API_ENDPOINT}/tasks/`,
          {
            headers: {
              Authorization: `JWT ${token}`,
            },
          });
        setTasks(response.data);
      } catch (error) {
        console.error("Error fetching tasks", error);
        toast({
          title: "Error fetching tasks",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    };
    const fetchQuestions = async (taskId) => {
      const token = localStorage.getItem('token');
  
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_API_ENDPOINT}/tasks/${taskId}/questions/`, {
          headers: {
            Authorization: `JWT ${token}`,
          },
        });
  
        // Navigate to the ProblemBankResultDashboard and pass the questions and task details
        navigate('/problem-bank-result', {
          state: {
            workbook: { title: `Task ${taskId}`, questions: response.data },
            passages: {}, // If you have passages, pass them here
            student: studentID,
          }
        });
  
      } catch (error) {
        console.error("Error fetching questions", error);
        toast({
          title: "Error fetching questions",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    };
  
    const handleAssignTask = async (taskId) => {
      try {
        await axios.post(`${process.env.REACT_APP_BACKEND_API_ENDPOINT}/teacher/assign-task/`, 
        { task_id: taskId,
          student_id: location.state.studentID}, 
        {
          headers: {
            Authorization: `JWT ${localStorage.getItem('token')}`,
          },
        });
        toast({
          title: "문제집이 성공적으로 할당되었습니다.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      } catch (error) {
        console.error("Error assigning task", error);
        toast({
          title: "Error assigning task",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    };
  
    return (
      <ChakraProvider>
        <Flex flexDirection="column" pt={{ base: "120px", md: "75px" }}>
          <Grid templateColumns={{ md: "1fr", lg: "1fr" }} templateRows={{ md: "1fr auto", lg: "1fr" }} my="26px" gap="24px">
            <Box bg="white" borderWidth="1px" borderRadius="lg" p={5}>
              <Heading size="md" mb={5}>할당 가능한 문제집</Heading>
              <Stack spacing={5}>
                {tasks.map(task => (
                  <Card key={task.id} variant="outline">
                    <CardBody>
                      <Flex justifyContent="space-between">
                        <Text>{task.name}</Text>
                        <ButtonGroup>
                        <Button colorScheme="teal" onClick={() => handleAssignTask(task.id)}>할당</Button>
                        <Button colorScheme="orange" onClick={() => fetchQuestions(task.id)}>문제집 보기</Button>
                        </ButtonGroup>
                      </Flex>
                    </CardBody>
                  </Card>
                ))}
              </Stack>
            </Box>
          </Grid>
        </Flex>
      </ChakraProvider>
    );
  };