import {
  Flex,
  Box,
  Heading,
  Text,
  VStack,
  SimpleGrid,
  Grid,
  GridItem,
  Skeleton,
  Button,
  useToast,
  Center,
  RadioGroup,
  Radio,
  Icon,
  HStack,
  Stack,
} from "@chakra-ui/react";
import { useLocation, useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import axios from "axios";
import { FcOk, FcCancel } from "react-icons/fc";
import 'katex/dist/katex.min.css'; // 필요한 경우 추가

export default function GradeQuestionDashboard() {
  const location = useLocation();
  const navigate = useNavigate();
  const { root_question_ids, iteration, student } = location.state || { root_question_ids: [], iteration: [] };
  const toast = useToast();
  const [questions, setQuestions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [grades, setGrades] = useState({});

  useEffect(() => {
    const fetchQuestions = async () => {
      setIsLoading(true);
      const token = localStorage.getItem('token');
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_API_ENDPOINT}/api/related-questions/`, {
          headers: {
            Authorization: `JWT ${token}`,
          },
          params: {
            student: student,
            root_question_ids: `[${root_question_ids.join(",")}]`,
            iteration: iteration,
          },
        });
        setQuestions(response.data);
      } catch (error) {
        console.error("Error fetching related questions:", error);
        toast({
          title: "Error fetching related questions",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      } finally {
        setIsLoading(false);
      }
    };

    fetchQuestions();
  }, [root_question_ids, iteration, student, toast]);

  const handleGradeChange = (questionId, value) => {
    setGrades({
      ...grades,
      [questionId]: value,
    });
  };

  const handleSubmitGrades = async () => {
    setIsSubmitting(true);
    const token = localStorage.getItem('token');
    const gradeData = {
      student: student,
      grades: Object.keys(grades).map(questionId => ({
        root_question_id: questionId,
        iteration: iteration,
        is_correct: grades[questionId] === 'true',
      })),
    };

    try {
      await axios.post(`${process.env.REACT_APP_BACKEND_API_ENDPOINT}/api/grade-student-questions/`, gradeData, {
        headers: {
          Authorization: `JWT ${token}`,
        },
      });
      toast({
        title: "Grades submitted successfully",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      navigate(-1); // Go back to the previous page
    } catch (error) {
      console.error("Error submitting grades:", error);
      toast({
        title: "Error submitting grades",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Flex flexDirection="column" pt={{ base: "120px", md: "75px" }}>
      <Grid templateColumns={{ md: "1fr", lg: "1fr" }} templateRows={{ md: "1fr auto", lg: "1fr" }} my="26px" gap="24px">
        <Box bg="white" borderWidth="1px" borderRadius="lg" p={5}>
          <Heading size="md" mb={5}>문제 채점</Heading>

          {isLoading ? (
            <SimpleGrid columns={2} spacing={10}>
              <Skeleton height="500px" borderRadius="lg" />
              <Skeleton height="500px" borderRadius="lg" />
            </SimpleGrid>
          ) : (
            questions.length > 0 ? (
              <SimpleGrid columns={2} spacing={10}>
                {questions.map((question, index) => (
                  <GridItem key={index} colSpan={1}>
                    <Box borderWidth="1px" borderRadius="lg" p={4} mb={4} position="relative">
                      <HStack spacing={10} justifyContent="space-between">
                        <div
                          dangerouslySetInnerHTML={{ __html: question.content }}
                        ></div>
                      </HStack>
                      {question.choice_1 ? ( <VStack align="start" mt={3} spacing={2}>
                        <HStack><Text>1.</Text><div
                          dangerouslySetInnerHTML={{ __html: question.choice_1 }}
                        ></div></HStack>
                        <HStack><Text>2.</Text><div
                          dangerouslySetInnerHTML={{ __html: question.choice_2 }}
                        ></div></HStack>
                        <HStack><Text>3.</Text><div
                          dangerouslySetInnerHTML={{ __html: question.choice_3 }}
                        ></div></HStack>
                        <HStack><Text>4.</Text><div
                          dangerouslySetInnerHTML={{ __html: question.choice_4 }}
                        ></div></HStack>
                        <HStack><Text>5.</Text><div
                          dangerouslySetInnerHTML={{ __html: question.choice_5 }}
                        ></div></HStack>
                      </VStack>) : (
                        <></>
                      )
                      }
                     
                      {grades[question.id] === 'true' ? (
                        <Icon as={FcOk} position="absolute" top={1} right={1} />
                      ) : grades[question.id] === 'false' ? (
                        <Icon as={FcCancel} position="absolute" top={1} right={1} />): null}
                      <RadioGroup mt={3} onChange={(value) => handleGradeChange(question.id, value)}>
                        <Stack direction="row">
                          <Radio value="true"><Icon as={FcOk} /> 정답</Radio>
                          <Radio value="false"><Icon as={FcCancel} /> 오답</Radio>
                        </Stack>
                      </RadioGroup>
                    </Box>
                  </GridItem>
                ))}
              </SimpleGrid>
            ) : (
              <Text textAlign="center">관련된 문제가 없습니다.</Text>
            )
          )}
          <Center mt={4}>
            <Button colorScheme="teal" onClick={handleSubmitGrades} isLoading={isSubmitting}>
              채점 제출
            </Button>
          </Center>
        </Box>
      </Grid>
    </Flex>
  );
}
