import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useToast,
  ChakraProvider,
  Grid,
  ButtonGroup,
  IconButton,
} from "@chakra-ui/react";
import { DeleteIcon } from "@chakra-ui/icons";
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

export default function StudentManageDashboard() {
  const [students, setStudents] = useState([]);
  const [newStudent, setNewStudent] = useState({ name: '', student_id: '', email: '' });
  const [isLoading, setLoading] = useState(false);
  const toast = useToast();
  const navigate = useNavigate();

  useEffect(() => {
    fetchStudents();
  }, []);

  const fetchStudents = async () => {
    const token = localStorage.getItem('token');

    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_API_ENDPOINT}/teacher/`,
        {
          headers: {
            Authorization: `JWT ${token}`,
          },
        });
      setStudents(response.data);
    } catch (error) {
      console.error("Error fetching students", error);
      toast({
        title: "Error fetching students",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewStudent({ ...newStudent, [name]: value });
  };

  const handleAddStudent = async () => {
    if (!newStudent.name || !newStudent.student_id || !newStudent.email) {
      toast({
        title: "모든 빈 칸을 채워주세요.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    setLoading(true);

    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_API_ENDPOINT}/teacher/`,
        newStudent, {
        headers: {
          Authorization: `JWT ${localStorage.getItem('token')}`,
        },
      });
      setStudents([...students, response.data]);
      setNewStudent({ name: '', student_id: '', email: '' });
      toast({
        title: "학생이 성공적으로 추가되었습니다.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Error adding student", error);
      toast({
        title: "Error adding student",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteStudent = async (studentID) => {
    const token = localStorage.getItem('token');
    try {
      await axios.delete(`${process.env.REACT_APP_BACKEND_API_ENDPOINT}/teacher/delete/${studentID}/`, {
        headers: {
          Authorization: `JWT ${token}`,
        },
      });
      setStudents(students.filter(student => student.id !== studentID));
      toast({
        title: "학생이 성공적으로 삭제되었습니다.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Error deleting student", error);
      toast({
        title: "Error deleting student",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleAssignTask = (studentID) => {
    navigate('/assign-task', { state: { studentID } });
  };

  const handleManageStudent = (studentID) => {
    navigate('/manage-student', { state: { studentID }});
  };

  return (
    <ChakraProvider>
      <Flex flexDirection="column" pt={{ base: "120px", md: "75px" }}>
        <Grid templateColumns={{ md: "1fr", lg: "1fr" }} templateRows={{ md: "1fr auto", lg: "1fr" }} my="26px" gap="24px">
          <Box bg="white" borderWidth="1px" borderRadius="lg" p={5} mb={6}>
            <Heading size="md" mb={5}>학생 목록</Heading>
            <Table variant="simple">
              <Thead>
                <Tr>
                  <Th>이름</Th>
                  <Th>등록일</Th>
                  <Th>작업</Th>
                  <Th>아이디</Th>
                  <Th>비밀번호</Th>
                  <Th>삭제</Th>
                </Tr>
              </Thead>
              <Tbody>
                {students.map(student => (
                  <Tr key={student.id}>
                    <Td>{student.name}</Td>
                    <Td>{student.created_at.split("T")[0]}</Td>
                    <Td>
                      <ButtonGroup>
                        <Button colorScheme="teal" onClick={() => handleAssignTask(student.id)}>문제 등록</Button>
                        <Button colorScheme="orange" onClick={() => handleManageStudent(student.id)}>학생 관리</Button>
                      </ButtonGroup>
                    </Td>
                    <Td>{student.id_stu}</Td> {/* Display the username */}
                    <Td>{student.password}</Td>  {/* Display the password */}
                    <Td>
                      <IconButton
                        icon={<DeleteIcon />}
                        colorScheme="red"
                        onClick={() => handleDeleteStudent(student.id)}
                      />
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Box>

          <Box bg="white" borderWidth="1px" borderRadius="lg" p={5}>
            <Heading size="md" mb={5}>학생 추가</Heading>
            <FormControl mb={3}>
              <FormLabel>학생 ID</FormLabel>
              <Input
                name="student_id"
                value={newStudent.student_id}
                onChange={handleInputChange}
                placeholder="학생 ID를 입력하세요"
              />
            </FormControl>
            <FormControl mb={3}>
              <FormLabel>학생 이름</FormLabel>
              <Input
                name="name"
                value={newStudent.name}
                onChange={handleInputChange}
                placeholder="학생 이름을 입력하세요"
              />
            </FormControl>
            <FormControl mb={3}>
              <FormLabel>학생 이메일</FormLabel>
              <Input
                name="email"
                value={newStudent.email}
                onChange={handleInputChange}
                placeholder="학생 이메일을 입력하세요"
              />
            </FormControl>
            <Button
              colorScheme="teal"
              onClick={handleAddStudent}
              isLoading={isLoading}
              loadingText="추가 중"
            >
              학생 추가
            </Button>
          </Box>
        </Grid>
      </Flex>
    </ChakraProvider>
  );
}
