// import
import Dashboard from "views/Dashboard/Dashboard";
import CreateSolutionDashBoard from "./views/Dashboard/Dashboard/CreateSolutionDashboard.js";
import ModifySolutionDashboard from "./views/Dashboard/Dashboard/ModifySolutionDashboard.js";
import ProblemBankDashboard from "./views/Dashboard/Dashboard/ProblemBankDashboard.js";
import StudentManageDashboard from "./views/Dashboard/Dashboard/StudentManageDashboard.js";
import Tables from "views/Dashboard/Tables";
import Billing from "views/Dashboard/Billing";
import Profile from "views/Dashboard/Profile";
import SignIn from "views/Auth/SignIn.js";
import SignUp from "views/Auth/SignUp.js";

import {
  HomeIcon,
  StatsIcon,
  CreditIcon,
  PersonIcon,
  DocumentIcon,
  RocketIcon,
  SupportIcon,
} from "./components/Icons/Icons.js";
import { TfiAgenda } from "react-icons/tfi";
import { FaBook, FaRobot, FaPen, FaUpload } from 'react-icons/fa';


var dashRoutes = [
  // {
  //   path: "/student-manage",
  //   name: "학생 관리",
  //   icon: <PersonIcon color="inherit" />,
  //   element: <StudentManageDashboard/>,
  //   layout: "",
  // },

  // {
  //   path: "/create-solution",
  //   name: "문제집 생성",
  //   icon: <FaPen color="inherit" />,
  //   element: <CreateSolutionDashBoard/>,
  //   layout: "",
  // },

  // {
  //   path: "/modify-problem",
  //   name: "문제 변형",
  //   icon: <StatsIcon color="inherit" />,
  //   element: <ModifySolutionDashboard/>,
  //   layout: "",
  // }, 
  {
    path: "/problem-bank-stu",
    name: "문제집",
    icon: <DocumentIcon color="inherit" />,
    element: <ProblemBankDashboard />,
    layout: "",
  },

  // {
  //   path: "/workbook-list",
  //   name: "만든 문제집",
  //   icon: <TfiAgenda color="inherit" />,
  //   element: <ProblemBankDashboard />,
  //   layout: "",
  // },


  // {
  //   path: "/billing",
  //   name: "Pricing",
  //   icon: <CreditIcon color="inherit" />,
  //   element: Billing,
  //   layout: "/admin",
  // },
  // {
  //   name: "ACCOUNT PAGES",
  //   category: "account",
  //   state: "pageCollapse",
  //   views: [
  //     {
  //       path: "/profile",
  //       name: "Profile",
  //       icon: <PersonIcon color="inherit" />,
  //       secondaryNavbar: true,
  //       element: Profile,
  //       layout: "/admin",
  //     },
  //     {
  //       path: "/signin",
  //       name: "Sign In",
  //       icon: <DocumentIcon color="inherit" />,
  //       element: SignIn,
  //       layout: "/auth",
  //     },
  //     {
  //       path: "/signup",
  //       name: "Sign Up",
  //       icon: <RocketIcon color="inherit" />,
  //       secondaryNavbar: true,
  //       element: SignUp,
  //       layout: "/auth",
  //     },
  //   ],
  // },
];
export default dashRoutes;
