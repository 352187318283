import {
    Flex,
    Box,
    Heading,
    Text,
    Button,
    VStack,
    List,
    ListItem,
    Skeleton,
    Stack,
    Grid,
  } from "@chakra-ui/react";
  import { useState, useEffect } from "react";
  import axios from "axios";
  import { useNavigate } from "react-router-dom";
  
  export default function WorkbookListDashboard() {
    const [workbooks, setWorkbooks] = useState([]);
    const [isLoading, setIsLoading] = useState(true); // 로딩 상태 추가
    const navigate = useNavigate();
  
    useEffect(() => {
      axios.get(`${process.env.REACT_APP_BACKEND_API_ENDPOINT}/problem_generator/workbooks/`)
        .then(response => {
          setWorkbooks(response.data);
          setIsLoading(false); // 데이터 로드 완료 후 로딩 상태 해제
        })
        .catch(error => {
          console.error("Error fetching workbooks:", error);
          setIsLoading(false); // 오류가 발생해도 로딩 상태 해제
        });
    }, []);
  
    const viewWorkbook = (workbook) => {
      navigate('/workbook-detail', { state: { workbook } });
    };
  
    return (
      <Flex flexDirection="column" pt={{ base: "120px", md: "75px" }}>
        <Grid templateColumns={{ md: "1fr", lg: "1fr" }} templateRows={{ md: "1fr auto", lg: "1fr" }} my="26px" gap="24px">
        <Box bg="white" borderWidth="1px" borderRadius="lg" p={5}>
          <Heading size="md" mb={5}>문제집 목록</Heading>
          <List spacing={4}>
            {isLoading ? (
              <Stack spacing={4}>
                {[1, 2, 3, 4, 5].map((_, index) => (
                  <Skeleton height="100px" borderRadius="lg" key={index} />
                ))}
              </Stack>
            ) : (
              workbooks.map(workbook => (
                <ListItem key={workbook.id} borderWidth="1px" borderRadius="lg" p={3} mb={4}>
                  <VStack align="start">
                    <Heading size="sm">{workbook.title}</Heading>
                    <Text>{workbook.description}</Text>
                    <Button colorScheme="teal" onClick={() => viewWorkbook(workbook)}>보기</Button>
                  </VStack>
                </ListItem>
              ))
            )}
          </List>
        </Box>
        </Grid>
      </Flex>
    );
  }
  