import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Flex,
  Heading,
  Text,
  ChakraProvider,
  Grid,
  Stack,
  useToast,
  Card,
  CardBody,
  IconButton,
  Input,
} from "@chakra-ui/react";
import { DeleteIcon } from "@chakra-ui/icons";
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';

export default function AssignTaskDashboard() {
  const [tasks, setTasks] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const toast = useToast();
  const location = useLocation();
  const navigate = useNavigate();
  const studentID = location.state?.studentID; // Optional chaining to safely access studentID

  useEffect(() => {
    fetchTasks();
  }, []);

  const fetchTasks = async () => {
    const token = localStorage.getItem('token');

    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_API_ENDPOINT}/tasks/stu-task/`, {
        headers: {
          Authorization: `JWT ${token}`,
        },
      });
      setTasks(response.data);
    } catch (error) {
      console.error("Error fetching tasks", error);
      toast({
        title: "Error fetching tasks",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const fetchQuestions = async (taskId) => {
    const token = localStorage.getItem('token');

    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_API_ENDPOINT}/tasks/${taskId}/questions/`, {
        headers: {
          Authorization: `JWT ${token}`,
        },
      });

      // Navigate to the ProblemBankResultDashboard and pass the questions and task details
      navigate('/problem-bank-result-stu', {
        state: {
          workbook: { title: `Task ${taskId}`, questions: response.data },
          passages: {}, // If you have passages, pass them here
          student: studentID,
        }
      });

    } catch (error) {
      console.error("Error fetching questions", error);
      toast({
        title: "Error fetching questions",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleDeleteTask = async (TaskID) => {
    const token = localStorage.getItem('token');
    try {
      await axios.delete(`${process.env.REACT_APP_BACKEND_API_ENDPOINT}/tasks/delete/${TaskID}/`, {
        headers: {
          Authorization: `JWT ${token}`,
        },
      });
      setTasks(tasks.filter(task => task.id !== TaskID));
      toast({
        title: "문제집이 성공적으로 삭제되었습니다.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Error deleting task", error);
      toast({
        title: "Error deleting task",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const filteredTasks = tasks.filter(task =>
    task.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <ChakraProvider>
    <Flex flexDirection="column" pt={{ base: "120px", md: "150px" }}>
      <Flex justifyContent="center" mb={5}>
        <Box w={{ base: "90%", md: "50%" }}>
          <Input
            placeholder="문제집 검색"
            value={searchQuery}
            onChange={handleSearchChange}
            size="md"
            bg="white"
            borderWidth="2px"
            borderStyle="solid"
            borderColor="gray.400"
            borderRadius="md"
            shadow="sm"
          />
        </Box>
      </Flex>
        <Grid templateColumns={{ md: "1fr", lg: "1fr" }} templateRows={{ md: "1fr auto", lg: "1fr" }} my="26px" gap="24px">
          <Box bg="white" borderWidth="1px" borderRadius="lg" p={5}>
            <Stack spacing={5}>
              {filteredTasks.map(task => (
                <Card key={task.id} variant="outline">
                  <CardBody>
                    <Flex justifyContent="space-between" alignItems="center">
                      <Text>{task.name}</Text>
                      <Flex alignItems="center">
                        <Button colorScheme="teal" onClick={() => fetchQuestions(task.id)}>문제집 보기</Button>
                        <IconButton
                          icon={<DeleteIcon />}
                          colorScheme="red"
                          onClick={() => handleDeleteTask(task.id)}
                          ml={2}
                        />
                      </Flex>
                    </Flex>
                  </CardBody>
                </Card>
              ))}
            </Stack>
          </Box>
        </Grid>
      </Flex>
    </ChakraProvider>
  );
}
