
export const uploadFileSampleData = [
  {
    name: "이미지 1",
    size: "2.3mb",
    type: "jpg",
  },
  {
    name: "이미지 2",
    size: "2.3mb",
    type: "png",
  },
  {
    name: "이미지 3",
    size: "2.3mb",
    type: "gif",
  },
  {
    name: "이미지 4",
    size: "2.3mb",
    type: "jpg",
  },
];
