// Chakra imports
import {
  Flex,
  Grid,
  Box,
  Center,
  Heading,
  Button,
  VStack,
  RadioGroup,
  Radio,
  IconButton,
  Text,
  HStack,
  useToast,
  Checkbox,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Input,
  Textarea,
} from "@chakra-ui/react";
import { MdOutlineDelete } from "react-icons/md";
import { Card, CardBody, CardFooter } from '@chakra-ui/react';
import { useState } from "react";
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import React from "react";
import { AddIcon } from "@chakra-ui/icons";

export default function CreateQuestionDashboard() {
  const [file, setFile] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [cards, setCards] = useState([]);
  const [generatedQuestions, setGeneratedQuestions] = useState([]);
  const [solutions, setSolutions] = useState([]);
  const [creationMode, setCreationMode] = useState(null); // 문제 생성 모드 상태 추가
  const navigate = useNavigate();
  const location = useLocation();
  const toast = useToast();
  const [checkedBoxList, setCheckedBoxList] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [manualQuestion, setManualQuestion] = useState({
    passage: location.state.passage_id,
    question: '',
    choice_1: '',
    choice_2: '',
    choice_3: '',
    choice_4: '',
    choice_5: '',
  });
  const [manualSolution, setManualSolution] = useState({
    answer: '',
    explanation: '',
  });

  const options = [
    '글의 세부 정보 확인', '글의 중심 내용 파악', '문단의 성격 파악', '글의 내용 추리', '글쓴이의 관점 추리', '글쓴이의 태도 및 의도 파악',
    '전제나 결론 추리', '추론 방식 파악', '다른 상황에 적용', '서술 방식 파악, 서술의 특징과 효과', '글을 읽고 난 후의 반응 추리'
  ];

  const addCard = () => {
    if (cards.length >= 5) {
      toast({
        title: "한 지문 당 문제 생성은 5개 미만만 가능합니다.",
        status: "warning",
        duration: 3000,
        isClosable: true,
        position: "top",
      });
      return;
    }
    setCards((prevCards) => [
      ...prevCards,
      { id: prevCards.length, selectedOption: null }
    ]);
  };

  const handleRadioChange = (id, value) => {
    setCards((prevCards) =>
      prevCards.map((card) =>
        card.id === id ? { ...card, selectedOption: value } : card
      )
    );
  };

  const deleteCard = (id) => {
    setCards((prevCards) =>
      prevCards.filter((card) => card.id !== id)
    );
  };

  const createQuestion = () => {
    const selectedOptions = cards.map(card => card.selectedOption);
    const data = {
      passage_id: location.state.passage_id,
      passage_title: location.state.title,
      contents: location.state.contents,
      selected_options: selectedOptions
    };

    setLoading(true);
    axios.post(`${process.env.REACT_APP_BACKEND_API_ENDPOINT}/problem_generator/generate/`, data)
      .then(response => {
        setGeneratedQuestions(response.data.questions);
        setSolutions(response.data.solutions);
        toast({
          title: "문제가 성공적으로 생성되었습니다.",
          status: "success",
          duration: 3000,
          isClosable: true,
          position: "top",
        });
      })
      .catch(err => {
        console.log(err);
        toast({
          title: "문제 생성에 실패했습니다.",
          status: "error",
          duration: 3000,
          isClosable: true,
          position: "top",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleCheckboxChange = (e, index) => {
    if (e.target.checked) {
      setCheckedBoxList((prevList) => [...prevList, index]);
    } else {
      setCheckedBoxList((prevList) => prevList.filter(item => item !== index));
    }
  };

  const saveSelectedQuestions = () => {
    const selectedQuestions = checkedBoxList.map(index => generatedQuestions[index]);
    const selectedSolutions = checkedBoxList.map(index => solutions[index]);
    const data = {
      questions: selectedQuestions,
      solutions: selectedSolutions
    };

    setLoading(true);

    axios.post(`${process.env.REACT_APP_BACKEND_API_ENDPOINT}/problem_generator/save_selected_questions/`, data)
      .then(response => {
        toast({
          title: "문제가 성공적으로 저장되었습니다.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        navigate('/problem-bank');
      })
      .catch(err => {
        console.log(err);
        toast({
          title: "문제 저장에 실패했습니다.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const openManualQuestionModal = () => {
    setCreationMode('manual');
    setIsModalOpen(true);
  };

  const openGeneratedQuestionMode = () => {
    setCreationMode('generated');
    setCards([]); // 초기화
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setManualQuestion({
      passage: location.state.passage_id,
      question: '',
      choice_1: '',
      choice_2: '',
      choice_3: '',
      choice_4: '',
      choice_5: '',
    });
    setManualSolution({
      answer: '',
      explanation: '',
    });
  };

  const handleManualQuestionChange = (e) => {
    const { name, value } = e.target;
    setManualQuestion((prev) => ({
      ...prev,
      [name]: value,
      passage: location.state.passage_id, // 이전 값을 유지
    }));
  };
  
  const handleManualSolutionChange = (e) => {
    const { name, value } = e.target;
    setManualSolution((prev) => ({ ...prev, [name]: value }));
  };

  const saveManualQuestion = () => {
    setGeneratedQuestions((prevQuestions) => [...prevQuestions, manualQuestion]);
    setSolutions((prevSolutions) => [...prevSolutions, manualSolution]);

    closeModal();
  };

  return (
    <Flex flexDirection="column" pt={{ base: "120px", md: "75px" }}>
      <Grid
        templateColumns={{ md: "1fr", lg: "1fr" }}
        templateRows={{ md: "1fr auto", lg: "1fr" }}
        my="26px"
        gap="24px"
      >
        <Box bg="white" borderWidth="1px" borderRadius="lg" p={3}>
          <Box p={3}>
            <Center>
              <Heading size="md">{location.state.title}</Heading>
            </Center>
          </Box>
          <Box bg="white" borderWidth="1px" borderRadius="lg" p={3}>
            {location.state.contents}
          </Box>
          <Center mt={3}>
            {(!creationMode || creationMode !== 'generated') && (
              <>
                <Button colorScheme="teal" onClick={openGeneratedQuestionMode} isLoading={isLoading} mr={3}>
                  문제 유형 생성
                </Button>
                <Button colorScheme="teal" variant="outline" onClick={openManualQuestionModal}>
                  직접 문제 생성
                </Button>
              </>
            )}
            {creationMode === 'generated' && (
              <>
                <VStack spacing={4} mt={4}>
                  {cards.map((card) => (
                    <Card key={card.id} variant="outline" width="100%">
                      <CardBody>
                        <RadioGroup
                          onChange={(value) => handleRadioChange(card.id, value)}
                          value={card.selectedOption}
                        >
                          <HStack wrap="wrap" spacing={4}>
                            {options.map((option) => (
                              <Card key={option} variant="outline" padding={2} minWidth="150px">
                                <CardBody>
                                  <Radio value={option}>
                                    <Text>{option}</Text>
                                  </Radio>
                                </CardBody>
                              </Card>
                            ))}
                          </HStack>
                        </RadioGroup>
                      </CardBody>
                      <CardFooter justifyContent="flex-end">
                        <IconButton
                          variant="outline"
                          onClick={() => deleteCard(card.id)}
                          icon={<MdOutlineDelete />}
                          aria-label="Delete card"
                        />
                      </CardFooter>
                    </Card>
                  ))}
                <Center mt={3}>
                  <IconButton
                    onClick={addCard}
                    isRound={true}
                    variant='solid'
                    colorScheme='teal'
                    aria-label='Add Card'
                    icon={<AddIcon />}
                  />
                </Center>
                <Center mt={3}>
                  <Button colorScheme="teal" onClick={createQuestion} isLoading={isLoading}>문제 생성</Button>
                </Center>
                </VStack>
              </>
            )}
          </Center>
        </Box>
      </Grid>

      <Modal isOpen={isModalOpen} onClose={closeModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>직접 문제 생성</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack spacing={3}>
              <Input
                placeholder="문제를 입력하세요"
                name="question"
                value={manualQuestion.question}
                onChange={handleManualQuestionChange}
              />
              <Input
                placeholder="선택지 1"
                name="choice_1"
                value={manualQuestion.choice_1}
                onChange={handleManualQuestionChange}
              />
              <Input
                placeholder="선택지 2"
                name="choice_2"
                value={manualQuestion.choice_2}
                onChange={handleManualQuestionChange}
              />
              <Input
                placeholder="선택지 3"
                name="choice_3"
                value={manualQuestion.choice_3}
                onChange={handleManualQuestionChange}
              />
              <Input
                placeholder="선택지 4"
                name="choice_4"
                value={manualQuestion.choice_4}
                onChange={handleManualQuestionChange}
              />
              <Input
                placeholder="선택지 5"
                name="choice_5"
                value={manualQuestion.choice_5}
                onChange={handleManualQuestionChange}
              />
              <Input
                placeholder="정답"
                name="answer"
                value={manualSolution.answer}
                onChange={handleManualSolutionChange}
              />
              <Textarea
                placeholder="해설을 입력하세요"
                name="explanation"
                value={manualSolution.explanation}
                onChange={handleManualSolutionChange}
              />
            </VStack>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="teal" onClick={saveManualQuestion}>저장</Button>
            <Button variant="ghost" onClick={closeModal}>취소</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {generatedQuestions.length > 0 && (
        <Grid templateColumns={{ md: "1fr", lg: "1fr" }} templateRows={{ md: "1fr auto", lg: "1fr" }} my="26px" gap="24px">
          <Box bg="white" borderWidth="1px" borderRadius="lg" p={3}>
            <Box p={3}>
              <Center>
                <Heading size="md">생성된 문제 및 해설</Heading>
              </Center>
            </Box>
            {generatedQuestions.map((question, index) => (
              <Box key={index} bg="gray.50" borderWidth="1px" borderRadius="lg" p={3} mb={4}>
                <Checkbox colorScheme="teal" size="lg" mb={2} onChange={(e) => handleCheckboxChange(e, index)} />
                <Heading size="sm">문제 {index + 1}: {question.question}</Heading>
                <VStack spacing={2} mt={2} alignItems="start">
                  <Text>1. {question.choice_1}</Text>
                  <Text>2. {question.choice_2}</Text>
                  <Text>3. {question.choice_3}</Text>
                  <Text>4. {question.choice_4}</Text>
                  <Text>5. {question.choice_5}</Text>
                  <Text fontWeight="bold">정답: {solutions[index]?.answer}</Text>
                  <Text mt={2} fontWeight="bold">해설:</Text>
                  <Text>{solutions[index]?.explanation}</Text>
                </VStack>
              </Box>
            ))}
            {checkedBoxList.length > 0 && (
              <Center>
                <Button colorScheme="teal" isLoading={isLoading} onClick={saveSelectedQuestions}>
                  선택된 문제 저장
                </Button>
              </Center>
            )}
          </Box>
        </Grid>
      )}
    </Flex>
  );
}
