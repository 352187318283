import {
  Flex,
  Grid,
  Box,
  Center,
  Button,
  Heading,
  VStack,
  Text,
  Input,
  HStack,
  useToast,
  Skeleton,
  SkeletonText,
  Stack,
  Checkbox,
  Spinner,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import { useState, useEffect } from "react";
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import React from "react";

export default function ProblemBankDashboard() {
  const [data, setData] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [categoryLarge, setCategoryLarge] = useState("");
  const [categoryMedium, setCategoryMedium] = useState("");
  const [selectedSmallCategories, setSelectedSmallCategories] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedQuestions, setSelectedQuestions] = useState([]);
  const [taskTitle, setTaskTitle] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const toast = useToast();
  const navigate = useNavigate();

  const unit_large = ["전체", "I. 지수함수와 로그함수", "II. 삼각함수", "III. 수열"];
  const unit_medium = {
    "I. 지수함수와 로그함수": ["전체", "01. 지수", "02. 로그", "03. 지수함수", "04. 로그함수"],
    "II. 삼각함수": ["전체", "05. 삼각함수", "06. 삼각함수의 그래프", "07. 삼각함수의 활용"],
    "III. 수열": ["전체", "08. 등차수열과 등비수열", "09. 수열의 합", "10. 수학적 귀납법"],
  };
  const unit_small = {
    "01. 지수": ["01-1 거듭제곱과 거듭제곱근", "01-2 거듭제곱근의 성질", "01-3 지수의 확장"],
    "02. 로그": ["02-1 로그의 정의", "02-2 로그의 성질", "02-3 로그의 밑의 변환", "02-4 상용로그"],
    "03. 지수함수": ["03-1 지수함수", "03-2 지수함수 y=a^(x)의 성질", "03-3 지수함수 y=a^(x)의 그래프의 평행이동과 대칭이동", "03-4 지수함수의 최대, 최소", "03-5 지수방정식", "03-6 지수부등식"],
    "04. 로그함수": ["04-1 로그함수", "04-2 로그함수 y=log_(a){x}의 성질", "04-3 로그함수 y=log_(a){x}의 그래프의 평행이동과 대칭이동", "04-4 로그함수의 최대, 최소", "04-5 로그방정식", "04-6 로그부등식"],
    "05. 삼각함수": ["05-1 일반각", "05-2 호도법", "05-3 부채꼴의 호의 길이와 넓이", "05-4 삼각함수", "05-5 삼각함수의 값의 부호", "05-6 삼각함수 사이의 관계"],
    "06. 삼각함수의 그래프": ["06-1 주기함수", "06-2 함수 y=sin(x), y=cos(x)의 성질", "06-3 함수 y=tan(x)의 성질", "06-4 삼각함수의 최대, 최소와 주기", "06-5 여러 가지 각에 대한 삼각함수의 성질", "06-6 삼각방정식과 삼각부등식"],
    "07. 삼각함수의 활용": ["07-1 사인법칙", "07-2 코사인법칙", "07-3 삼각형의 넓이"],
    "08. 등차수열과 등비수열": ["08-1 수열", "08-2 등차수열", "08-3 등차수열의 합", "08-4 수열의 합과 일반항 사이의 관계", "08-5 등비수열", "08-6 등비수열의 합"],
    "09. 수열의 합": ["09-1 합의 기호 ∑", "09-2 ∑의 성질", "09-3 자연수의 거듭제곱의 합", "09-4 분수의 꼴인 수열의 합"],
    "10. 수학적 귀납법": ["10-1 수열의 귀납적 정의", "10-2 등차수열과 등비수열의 귀납적 정의", "10-3 수학적 귀납법"],
  };

  const handleSelectLarge = (category) => {
    setCategoryLarge(category === "전체" ? "" : category);
    setCategoryMedium("");
    setSelectedSmallCategories([]);
    fetchData(category === "전체" ? "" : category, "", 1);
  };

  const handleSelectMedium = (category) => {
    setCategoryMedium(category === "전체" ? "" : category);
    setSelectedSmallCategories([]);
    fetchData(categoryLarge, category === "전체" ? "" : category, 1);
  };

  const handleSmallCategoryChange = (category) => {
    const updatedSelectedCategories = selectedSmallCategories.includes(category)
      ? selectedSmallCategories.filter((item) => item !== category)
      : [...selectedSmallCategories, category];
    setSelectedSmallCategories(updatedSelectedCategories);
    fetchData(categoryLarge, categoryMedium, 1, updatedSelectedCategories);
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    fetchData(categoryLarge, categoryMedium, 1, selectedSmallCategories, e.target.value);
  };

  const fetchData = async (large, medium, page, small = selectedSmallCategories, search = searchTerm) => {
    setLoading(true);
    const token = localStorage.getItem('token');
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_API_ENDPOINT}/api/questions/`, 
      {
        params: {
          large_unit: large || undefined,
          medium_unit: medium || undefined,
          small_unit: small.length ? small.join("|") : undefined,
          search: search || undefined,
          page: page,
        },
        headers: {
          Authorization: `JWT ${token}`,
        },
      });
      setData(response.data.results);
      setTotalPages(Math.ceil(response.data.count / 10));
      setCurrentPage(page);
    } catch (error) {
      console.error("Error fetching data", error);
      toast({
        title: "데이터 가져오기 실패",
        description: "데이터를 가져오는 중 오류가 발생했습니다.",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top",
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData(categoryLarge, categoryMedium, currentPage);
  }, [categoryLarge, categoryMedium, currentPage, selectedSmallCategories]);

  const handleCheckboxChange = (questionId) => {
    setSelectedQuestions((prevSelected) =>
      prevSelected.includes(questionId)
        ? prevSelected.filter((id) => id !== questionId)
        : [...prevSelected, questionId]
    );
  };

  const handleCreateTask = async () => {
    if (!taskTitle.trim()) {
      toast({
        title: "문제집 제목을 입력하세요",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top",
      });
      return;
    }

    setIsSubmitting(true);
    const token = localStorage.getItem('token');
    try {
      await axios.post(`${process.env.REACT_APP_BACKEND_API_ENDPOINT}/api/tasks/`, 
      {
        title: taskTitle,
        question_ids: selectedQuestions,
        categories: selectedSmallCategories,
      },
      {
        headers: {
          Authorization: `JWT ${token}`,
        },
      });
      toast({
        title: "문제집 생성 성공",
        description: "문제집이 성공적으로 생성되었습니다.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      navigate('/problem-bank');
    } catch (error) {
      console.error("Error creating task", error);
      toast({
        title: "문제집 생성 실패",
        description: "문제집 생성 중 오류가 발생했습니다.",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top",
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Flex flexDirection="column" pt={{ base: "120px", md: "75px" }}>
    <Grid templateColumns={{ md: "1fr", lg: "1fr" }} templateRows={{ md: "1fr auto", lg: "1fr" }} my="26px" gap="24px">
    <Box bg="white" borderWidth="1px" borderRadius="lg" p={3} mb={6}>
      <Center>
        <Heading size='md'>문제 생성</Heading>
      </Center>
      <Input
        placeholder="문제집 제목을 입력하세요"
        value={taskTitle}
        onChange={(e) => setTaskTitle(e.target.value)}
        mt={4}
        mb={4}
        size="lg"
        fontWeight="bold"
        // textAlign="center"
      />
      <Input
        placeholder="검색어를 입력하세요"
        value={searchTerm}
        onChange={handleSearch}
        mt={4}
        mb={4}
        size="lg"
        fontWeight="bold"
        // textAlign="center"
      />
      <Text fontWeight="bold" textAlign="center">대단원</Text>
      <HStack spacing={4} justify="center">
        {unit_large.map((cat) => (
          <Button 
            key={cat} 
            colorScheme={categoryLarge === cat ? 'teal' : 'gray'} 
            variant={categoryLarge === cat ? 'solid' : 'outline'}
            onClick={() => handleSelectLarge(cat)}
          >
            {cat}
          </Button>
        ))}
      </HStack>
      {categoryLarge && (
        <>
          <Text mt={4} fontWeight="bold" textAlign="center">중단원</Text>
          <HStack spacing={4} justify="center">
            {unit_medium[categoryLarge]?.map((cat) => (
              <Button 
                key={cat} 
                colorScheme={categoryMedium === cat ? 'teal' : 'gray'} 
                variant={categoryMedium === cat ? 'solid' : 'outline'}
                onClick={() => handleSelectMedium(cat)}
              >
                {cat}
              </Button>
            ))}
          </HStack>
        </>
      )}
      {categoryMedium && (
        <>
          <Text mt={4} fontWeight="bold" textAlign="center">소단원</Text>
          <Wrap spacing={4} justify="center">
            {unit_small[categoryMedium]?.map((cat) => (
              <WrapItem key={cat}>
                <Checkbox
                  isChecked={selectedSmallCategories.includes(cat)}
                  onChange={() => handleSmallCategoryChange(cat)}
                >
                  {cat}
                </Checkbox>
              </WrapItem>
            ))}
          </Wrap>
        </>
      )}
      <br />
      {isLoading ? (
        <Stack spacing={4}>
          {[1, 2, 3].map((_, index) => (
            <Box key={index} padding="6" boxShadow="lg" bg="white">
              <Skeleton height="20px" />
              <SkeletonText mt="4" noOfLines={4} spacing="4" />
              <Skeleton height="20px" mt="4" />
            </Box>
          ))}
        </Stack>
      ) : (
        <Box>
          {data.map((question, index) => (
            <Box key={index} p={4} borderWidth="1px" borderRadius="lg" mb={4}>
              <HStack spacing={4}>
                <Checkbox
                  isChecked={selectedQuestions.includes(question.id)}
                  onChange={() => handleCheckboxChange(question.id)}
                />
                <Text as='b'>{question.number}번</Text>
                <Text color='gray.500'>
                  {(question.category && question.category.length > 0) ? question.category.map(cat => cat.name).join(', ') : 'No Category'}
                </Text>
              </HStack>
              <div
                dangerouslySetInnerHTML={{ __html: question.content }}
              ></div>
            </Box>
          ))}

          <Button
            mt={4}
            colorScheme="teal"
            onClick={handleCreateTask}
            isDisabled={selectedQuestions.length === 0}
            isLoading={isSubmitting}
          >
            문제집 만들기
          </Button>
          <HStack mt={4} spacing={4} justify="center">
            <Button
              colorScheme="teal"
              variant="outline"
              onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
              isDisabled={currentPage === 1}
            >
              이전
            </Button>
            <Text>페이지 {currentPage} / {totalPages}</Text>
            <Button
              colorScheme="teal"
              variant="outline"
              onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
              isDisabled={currentPage === totalPages}
            >
              다음
            </Button>
          </HStack>
        </Box>
      )}
    </Box>
  </Grid>
</Flex>
);
}
