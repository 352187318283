import React, { useState } from 'react';
import {
  Flex,
  Box,
  Heading,
  Text,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  Button,
  Checkbox,
  Grid,
  Card,
  CardBody,
  useRadio,
  useRadioGroup,
  Center,
  Input,
} from "@chakra-ui/react";
import axios from "axios";
import { useNavigate } from 'react-router-dom';

function RadioCard(props) {
  const { getInputProps, getCheckboxProps } = useRadio(props);
  const input = getInputProps();
  const checkbox = getCheckboxProps();

  return (
    <Box as="label">
      <input {...input} />
      <Box
        {...checkbox}
        cursor="pointer"
        borderWidth="1px"
        borderRadius="md"
        boxShadow="md"
        _checked={{
          bg: "teal.600",
          color: "white",
          borderColor: "teal.600",
        }}
        _focus={{
          boxShadow: "outline",
        }}
        px={5}
        py={3}
      >
        {props.children}
      </Box>
    </Box>
  );
}

export default function CreatePassage() {
  const [length, setLength] = useState(1000); // 기본 텍스트 길이
  const [isStructureCheck, setIsStructureCheck] = useState(false);
  const [category, setCategory] = useState("인문"); // 기본 카테고리
  const [isLoading, setIsLoading] = useState(false); // 로딩 상태
  const [generatedPassage, setGeneratedPassage] = useState(null); // 생성된 지문
  const [title, setTitle] = useState(""); // 지문 제목
  const navigate = useNavigate();

  const handleGenerate = () => {
    if (title.trim() === "") {
      alert("지문 제목을 입력해주세요.");
      return;
    }

    setIsLoading(true);
    const data = {
      length,
      isStructureCheck,
      category,
      title
    };

    axios.post(`${process.env.REACT_APP_BACKEND_API_ENDPOINT}/problem_generator/generate_passage/`, data)
      .then(response => {
        setGeneratedPassage(response.data); // 생성된 지문 저장
        setIsLoading(false);
        console.log(response.data);
      })
      .catch(error => {
        console.error("Error generating passage:", error);
        setIsLoading(false);
      });
  };

  const handleCreateQuestion = () => {
    navigate('/create-question', { 
      state: { 
        passage_id: generatedPassage.id,
        title: generatedPassage.passage.title, 
        contents: generatedPassage.passage.contents 
      } 
    });
  };

  const options = ["인문", "사회", "과학", "기술", "예술", "융합"];

  const { getRootProps, getRadioProps } = useRadioGroup({
    name: "category",
    defaultValue: "인문",
    onChange: setCategory,
  });

  const group = getRootProps();

  return (
    <Flex flexDirection="column" pt={{ base: "120px", md: "75px" }}>
      <Grid templateColumns={{ md: "1fr", lg: "1fr" }} templateRows={{ md: "1fr auto", lg: "1fr" }} my="26px" gap="24px">
        <Box bg="white" borderWidth="1px" borderRadius="lg" p={5} width={{ base: "100%", md: "80%" }}>
          <Heading size="lg" mb={4} textAlign="center">지문 생성하기</Heading>
          
          <Text mb={2}>지문 제목</Text>
          <Input
            placeholder="지문 제목을 입력하세요"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            mb={4}
          />

          <Text mb={2}>텍스트 길이</Text>
          <Slider
            aria-label="text-length-slider"
            defaultValue={1000}
            min={300}
            max={3000}
            step={100}
            onChange={(val) => setLength(val)}
            mb={4}
          >
            <SliderTrack>
              <SliderFilledTrack />
            </SliderTrack>
            <SliderThumb boxSize={6}>
              <Box color="teal" />
            </SliderThumb>
          </Slider>
          <Text fontSize="sm" mb={4}>{length}자</Text>

          <Text mb={2}>카테고리 선택</Text>
          <Flex {...group} wrap="wrap" mb={4}>
            {options.map((value) => {
              const radio = getRadioProps({ value });
              return (
                <Card key={value} width="calc(33.33% - 10px)" m={2}>
                  <CardBody p={0}>
                    <RadioCard {...radio}><Center>{value}</Center></RadioCard>
                  </CardBody>
                </Card>
              );
            })}
          </Flex>
          <Button
            colorScheme="teal"
            onClick={handleGenerate}
            width="100%"
            isLoading={isLoading} // 로딩 상태 반영
            loadingText="지문 생성 중..."
          >
            지문 생성
          </Button>
        </Box>

        {generatedPassage && ( // 지문이 생성된 경우에만 표시
          <Box bg="white" borderWidth="1px" borderRadius="lg" p={5} mt={5} width={{ base: "100%", md: "80%" }}>
            <Heading size="md" mb={4}>생성된 지문</Heading>
            <Text whiteSpace="pre-wrap">{generatedPassage.passage.contents}</Text>
            <Button
              mt={4}
              colorScheme="teal"
              width="100%"
              onClick={handleCreateQuestion}
            >
              문제 생성하기
            </Button>
          </Box>
        )}
      </Grid>
    </Flex>
  );
}
