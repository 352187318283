import {
    Flex,
    Box,
    Heading,
    Text,
    VStack,
    SimpleGrid,
    Grid,
    GridItem,
    Skeleton,
    useToast,
    HStack,
    Icon
  } from "@chakra-ui/react";
  import { useLocation } from 'react-router-dom';
  import React, { useEffect, useState } from 'react';
  import axios from "axios";
  import { FcOk, FcCancel } from "react-icons/fc";
  import 'katex/dist/katex.min.css'; // 필요한 경우 추가
  
  export default function ModifyProblemDashboard() {
    const location = useLocation();
    const toast = useToast();
    const { root_question_ids, iteration, student } = location.state || { root_question_ids: [], iteration: [] };
    const [questions, setQuestions] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
  
    useEffect(() => {
      const fetchQuestions = async () => {
        setIsLoading(true);
        const token = localStorage.getItem('token');
  
        try {
          const response = await axios.get(`${process.env.REACT_APP_BACKEND_API_ENDPOINT}/api/related-questions/`, {
            headers: {
              Authorization: `JWT ${token}`,
            },
            params: {
              student: student,
              root_question_ids: `[${root_question_ids.join(",")}]`,
              iteration: iteration,
            },
          });
          setQuestions(response.data);
        } catch (error) {
          console.error("Error fetching related questions:", error);
          toast({
            title: "Error fetching related questions",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        } finally {
          setIsLoading(false);
        }
      };
  
      fetchQuestions();
    }, [root_question_ids, iteration, student, toast]);
  
    return (
      <Flex flexDirection="column" pt={{ base: "120px", md: "75px" }}>
        <Grid templateColumns={{ md: "1fr", lg: "1fr" }} templateRows={{ md: "1fr auto", lg: "1fr" }} my="26px" gap="24px">
          <Box bg="white" borderWidth="1px" borderRadius="lg" p={5}>
            <Heading size="md" mb={5}>문제 출제</Heading>
  
            {isLoading ? (
              <SimpleGrid columns={2} spacing={10}>
                <Skeleton height="500px" borderRadius="lg" />
                <Skeleton height="500px" borderRadius="lg" />
              </SimpleGrid>
            ) : (
              questions.length > 0 ? (
                <SimpleGrid columns={2} spacing={10}>
                  {questions.map((question, index) => (
                    <GridItem key={index} colSpan={1}>
                      <Box borderWidth="1px" borderRadius="lg" p={4} mb={4} position="relative">
                        <HStack spacing={10} justifyContent="space-between">
                          <div
                            dangerouslySetInnerHTML={{ __html: question.content }}
                          ></div>
                        </HStack>
                        <VStack align="start" mt={3} spacing={2}>
                          <HStack><Text>1.</Text><div
                            dangerouslySetInnerHTML={{ __html: question.choice_1 }}
                          ></div></HStack>
                          <HStack><Text>2.</Text><div
                            dangerouslySetInnerHTML={{ __html: question.choice_2 }}
                          ></div></HStack>
                          <HStack><Text>3.</Text><div
                            dangerouslySetInnerHTML={{ __html: question.choice_3 }}
                          ></div></HStack>
                          <HStack><Text>4.</Text><div
                            dangerouslySetInnerHTML={{ __html: question.choice_4 }}
                          ></div></HStack>
                          <HStack><Text>5.</Text><div
                            dangerouslySetInnerHTML={{ __html: question.choice_5 }}
                          ></div></HStack>
                        </VStack>
                      </Box>
                    </GridItem>
                  ))}
                </SimpleGrid>
              ) : (
                <Text textAlign="center">관련된 문제가 없습니다.</Text>
              )
            )}
          </Box>
        </Grid>
      </Flex>
    );
  }
  