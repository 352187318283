import React, { useState } from 'react';
import {
  Flex,
  Grid,
  Box,
  VStack,
  Center,
  Button,
  Heading,
  Textarea,
  Input,
  IconButton,
  useToast,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  FormLabel,
  HStack,
  Divider,
  AbsoluteCenter
} from "@chakra-ui/react";
import { MdOutlineDelete } from "react-icons/md";
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

export default function WriteQuestionDashboard() {
  const [taskName, setTaskName] = useState(""); // Task 이름 입력 필드 추가
  const [questions, setQuestions] = useState([{ 
    number: '', 
    content: '', 
    choice_1: '', 
    choice_2: '', 
    choice_3: '', 
    choice_4: '', 
    choice_5: '', 
    answer: '', 
    solution: '' 
  }]);
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();
  const toast = useToast();

  const handleQuestionChange = (index, key, value) => {
    const newQuestions = [...questions];
    newQuestions[index][key] = value;
    setQuestions(newQuestions);
  };

  const handleAddQuestion = () => {
    setQuestions([...questions, { 
      number: 1, 
      content: '', 
      choice_1: '', 
      choice_2: '', 
      choice_3: '', 
      choice_4: '', 
      choice_5: '', 
      answer: '', 
      solution: '' 
    }]);
  };

  const handleRemoveQuestion = (index) => {
    const newQuestions = [...questions];
    newQuestions.splice(index, 1);
    setQuestions(newQuestions);
  };

  const handleSubmit = async () => {
    if (!taskName) {
      toast({
        title: "Task 이름을 입력해 주세요.",
        description: "Task 이름을 입력해야 합니다.",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
      return;
    }

    if (questions.some(question => !question.content || !question.number || !question.answer || !question.solution)) {
      toast({
        title: "모든 필드를 입력해 주세요.",
        description: "문제 번호, 문제 내용, 정답 및 해설을 모두 입력해야 합니다.",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
      return;
    }

    setLoading(true);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_API_ENDPOINT}/api/questions/`,
        { task_name: taskName, questions },
        {
          headers: {
            'content-type': 'application/json',
            Authorization: `JWT ${localStorage.getItem('token')}`,
          }
        }
      );
      console.log(response.data);
      navigate('/problem-bank'); // 수정 후 이동할 페이지로 navigate 설정
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Flex flexDirection="column" pt={{ base: "120px", md: "75px" }}>
      <Grid templateColumns={{ md: "1fr", lg: "1fr" }} templateRows={{ md: "1fr auto", lg: "1fr" }} my="26px" gap="24px">
        <Box bg="white" borderWidth="1px" borderRadius="lg" p={5}>
          <Box p={3}>
            <Center>
              <Heading size="md">문제집 입력</Heading>
            </Center>
          </Box>
          <VStack spacing={4} mt={4} width="100%">
            <Input
              value={taskName}
              onChange={(e) => setTaskName(e.target.value)}
              placeholder="문제집 이름을 입력하세요..."
              size="lg"
              mb={3}
              isDisabled={isLoading}
            />
            <Divider />
            {questions.map((question, index) => (
              <Box key={index} width="100%" borderWidth="1px" borderRadius="lg" p={4} mb={4} position="relative">
                <FormLabel>문제 번호를 입력하세요</FormLabel>
                <NumberInput
                  onChange={(value) => handleQuestionChange(index, 'number', value)}
                  placeholder="문제 번호를 입력하세요..."
                  size="lg"
                  mb={3}
                  isDisabled={isLoading}
                  min={1}
                  >
                  <NumberInputField />
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>
                <Textarea
                  value={question.content}
                  onChange={(e) => handleQuestionChange(index, 'content', e.target.value)}
                  placeholder="문제를 입력하세요..."
                  size="lg"
                  mb={3}
                  isDisabled={isLoading}
                />
                <Input
                  value={question.choice_1}
                  onChange={(e) => handleQuestionChange(index, 'choice_1', e.target.value)}
                  placeholder="선택지 1"
                  mb={3}
                  isDisabled={isLoading}
                />
                <Input
                  value={question.choice_2}
                  onChange={(e) => handleQuestionChange(index, 'choice_2', e.target.value)}
                  placeholder="선택지 2"
                  mb={3}
                  isDisabled={isLoading}
                />
                <Input
                  value={question.choice_3}
                  onChange={(e) => handleQuestionChange(index, 'choice_3', e.target.value)}
                  placeholder="선택지 3"
                  mb={3}
                  isDisabled={isLoading}
                />
                <Input
                  value={question.choice_4}
                  onChange={(e) => handleQuestionChange(index, 'choice_4', e.target.value)}
                  placeholder="선택지 4"
                  mb={3}
                  isDisabled={isLoading}
                />
                <Input
                  value={question.choice_5}
                  onChange={(e) => handleQuestionChange(index, 'choice_5', e.target.value)}
                  placeholder="선택지 5"
                  mb={3}
                  isDisabled={isLoading}
                />
                <Input
                  value={question.answer}
                  onChange={(e) => handleQuestionChange(index, 'answer', e.target.value)}
                  placeholder="정답"
                  mb={3}
                  isDisabled={isLoading}
                />
                <Textarea
                  value={question.solution}
                  onChange={(e) => handleQuestionChange(index, 'solution', e.target.value)}
                  placeholder="해설을 입력하세요..."
                  size="lg"
                  mb={3}
                  isDisabled={isLoading}
                />
                <IconButton
                  aria-label="Remove question"
                  icon={<MdOutlineDelete />}
                  size="lg"
                  position="absolute"
                  bottom="10px"
                  right="10px"
                  onClick={() => handleRemoveQuestion(index)}
                />
              </Box>
            ))}
            <HStack spacing={4} width="100%">
              <Button onClick={handleAddQuestion} colorScheme="teal" isDisabled={isLoading}>
                문제 추가
              </Button>
              <Button
                colorScheme="teal"
                onClick={handleSubmit}
                isLoading={isLoading}
                loadingText="제출 중"
              >
                문제집 만들기
              </Button>
            </HStack>
          </VStack>
        </Box>
      </Grid>
    </Flex>
  );
}
