// Chakra imports
import {
  Flex,
  Grid,
  Box,
  Text,
  Center,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Button,
  Heading,
  Skeleton,
  SkeletonText,
  Stack,
} from "@chakra-ui/react";
import { useState, useEffect } from "react";
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import React from "react";

export default function SelectPassageDashboard() {
  const [data, setData] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  // Fetch data from the server
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const token = localStorage.getItem('token');
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_API_ENDPOINT}/problem_generator/passages/?category=${location.state.type}`
        , {headers: {
          Authorization: `JWT ${token}`,
        }
      }); // Replace with your actual API endpoint
        setData(response.data);
      } catch (error) {
        console.error("Error fetching data", error);
        localStorage.removeItem('token');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [location.state.type]);

  // Function to handle the navigation with passage id
  const handleCreateQuestion = (passage) => {
      navigate('/create-question', 
        { state: {passage_id: passage.id, title: passage.title, contents: passage.contents} })
  };

  return (
    <Flex flexDirection="column" pt={{ base: "120px", md: "75px" }}>
      <Grid
        templateColumns={{ md: "1fr", lg: "1fr" }}
        templateRows={{ md: "1fr auto", lg: "1fr" }}
        my="26px"
        gap="24px"
      >
        <Box bg="white" borderWidth="1px" borderRadius="lg" padding={3}>
          <Box padding={3}>
            <Center>
              <Heading size='md'>문제 생성</Heading>
            </Center>
          </Box>
          <Accordion allowToggle>
            {isLoading ? (
              <Stack spacing={4}>
                {[1, 2, 3].map((_, index) => (
                  <Box key={index} padding="6" boxShadow="lg" bg="white">
                    <Skeleton height="20px" />
                    <SkeletonText mt="4" noOfLines={4} spacing="4" />
                    <Skeleton height="20px" mt="4" />
                  </Box>
                ))}
              </Stack>
            ) : (
              data.map((passage, index) => (
                <AccordionItem key={index}>
                  <h2>
                    <AccordionButton>
                      <Box flex="1" textAlign="left">
                        {passage.title}
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    <Box>
                      <Text>{passage.contents}</Text>
                    </Box>
                    <Box mt="2">
                      <Button onClick={() => handleCreateQuestion(passage)}>문제 생성하기</Button>
                    </Box>
                  </AccordionPanel>
                </AccordionItem>
              ))
            )}
          </Accordion>
        </Box>
      </Grid>
    </Flex>
  );
}
