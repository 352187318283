import {
  Flex,
  Box,
  Heading,
  Text,
  VStack,
  SimpleGrid,
  Grid,
  GridItem,
  useToast,
  HStack
} from "@chakra-ui/react";
import { useLocation } from 'react-router-dom';
import React from 'react';

export default function ProblemBankResultDashboard() {
  const location = useLocation();
  const { workbook, passages, student } = location.state || { workbook: { questions: [] }, passages: {}, student: null };
  const toast = useToast();
  const [questions, setQuestions] = React.useState(workbook.questions);

  return (
    <Flex flexDirection="column" pt={{ base: "120px", md: "75px" }}>
      <Grid templateColumns={{ md: "1fr", lg: "1fr" }} templateRows={{ md: "1fr auto", lg: "1fr" }} my="26px" gap="24px">
        <Box bg="white" borderWidth="1px" borderRadius="lg" p={5}>
          <Heading size="md" mb={5}>{workbook.title}</Heading>

          {questions.length > 0 ? (
            <SimpleGrid columns={2} spacing={10}>
              {questions.map((question, index) => (
                <GridItem key={index} colSpan={1}>
                  <Box borderWidth="1px" borderRadius="lg" p={4} mb={4} position="relative">
                    <div dangerouslySetInnerHTML={{ __html: question.content }}></div>
                    {question.choice_1 ? ( <VStack align="start" mt={3} spacing={2}>
                        <HStack><Text>1.</Text><div
                          dangerouslySetInnerHTML={{ __html: question.choice_1 }}
                        ></div></HStack>
                        <HStack><Text>2.</Text><div
                          dangerouslySetInnerHTML={{ __html: question.choice_2 }}
                        ></div></HStack>
                        <HStack><Text>3.</Text><div
                          dangerouslySetInnerHTML={{ __html: question.choice_3 }}
                        ></div></HStack>
                        <HStack><Text>4.</Text><div
                          dangerouslySetInnerHTML={{ __html: question.choice_4 }}
                        ></div></HStack>
                        <HStack><Text>5.</Text><div
                          dangerouslySetInnerHTML={{ __html: question.choice_5 }}
                        ></div></HStack>
                      </VStack>) : (
                        <></>
                      )}
                  </Box>
                </GridItem>
              ))}
            </SimpleGrid>
          ) : (
            <Text textAlign="center">관련된 문제가 없습니다.</Text>
          )}
        </Box>
      </Grid>
    </Flex>
  );
}