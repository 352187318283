import React, { useState, useEffect } from 'react';
import {
  Box,
  Flex,
  Heading,
  Table,
  Tbody,
  Tfoot,
  Td,
  Th,
  Thead,
  Tr,
  useToast,
  ChakraProvider,
  Grid,
  Icon,
  ButtonGroup,
  Button,
} from "@chakra-ui/react";
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';

import { FcOk, FcCancel, FcViewDetails } from "react-icons/fc";


export default function ManageStudentDashboard() {
  const [studentQuestions, setStudentQuestions] = useState([]);
  const toast = useToast();
  const location = useLocation();
  const navigate = useNavigate();

  const studentID = location.state?.studentID;

  useEffect(() => {
    fetchStudentQuestions();
  }, []);

  const fetchStudentQuestions = async () => {
    const token = localStorage.getItem('token');

    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_API_ENDPOINT}/api/student-questions/`, {
        headers: {
          Authorization: `JWT ${token}`,
        },
        params: {
          student: studentID,
        },
      });
      setStudentQuestions(response.data);
    } catch (error) {
      console.error("Error fetching student questions", error);
      toast({
        title: "Error fetching student questions",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const groupByRootQuestion = (questions) => {
    return questions.reduce((acc, question) => {
      const { root_question } = question;
      if (!acc[root_question]) {
        acc[root_question] = [];
      }
      acc[root_question].push(question);
      return acc;
    }, {});
  };

  const groupedQuestions = groupByRootQuestion(studentQuestions);

  const rootQuestionIds = Object.keys(groupedQuestions);

  const handleGrade = (rootQuestionIds, iteration) => {
    console.log(rootQuestionIds)
    navigate('/grade-question', {
      state: {
        root_question_ids: rootQuestionIds,
        iteration: iteration,
        student: studentID
      }
    });
  };

  const handleModify = (rootQuestionIds, iteration) => {
    console.log(rootQuestionIds)
    navigate('/modify-question', {
      state: {
        root_question_ids: rootQuestionIds,
        iteration: iteration,
        student: studentID
      }
    });
  };

  return (
    <ChakraProvider>
      <Flex flexDirection="column" pt={{ base: "120px", md: "75px" }}>
        <Grid templateColumns={{ md: "1fr", lg: "1fr" }} templateRows={{ md: "1fr auto", lg: "1fr" }} my="26px" gap="24px">
          <Box bg="white" borderWidth="1px" borderRadius="lg" p={5}>
            <Heading size="md" mb={5}>학생 문제 풀이 현황</Heading>
            <Table variant="simple">
              <Thead>
                <Tr>
                  <Th>문제</Th>
                  <Th>첫 번째 변형 문제</Th>
                  <Th>두 번째 변형 문제</Th>
                  <Th>세 번째 변형 문제</Th>
                  <Th>네 번째 변형 문제</Th>
                </Tr>
              </Thead>
              <Tbody>
                {Object.keys(groupedQuestions).map(rootQuestionId => (
                  <Tr key={rootQuestionId}>
                    <Td>{rootQuestionId}</Td>
                    {groupedQuestions[rootQuestionId].map((question, index) => (
                      <Td key={index}>
                        {question.is_correct==true ? <Icon as={FcOk} /> : question.is_correct==false ?  <Icon as={FcCancel} /> : <Icon as={FcViewDetails} /> }
                      </Td>
                    ))}
                    {[...Array(4 - groupedQuestions[rootQuestionId].length)].map((_, index) => (
                      <Td key={`empty-${index}`}>
                        {/* <Icon as={FcViewDetails} /> */}
                      </Td>
                    ))}
                  </Tr>
                ))}
              </Tbody>
              <Tfoot>
              <Tr>
                <Th>문제 출제</Th>
                <Th><ButtonGroup spacing={2} size="sm">
                    <Button colorScheme="teal" onClick={() => handleGrade(rootQuestionIds, 0)}>채점하기</Button>
                    <Button colorScheme="orange" onClick={() => handleModify(rootQuestionIds, 1)}>오답 변형 문제</Button>
                </ButtonGroup></Th>
                <Th><ButtonGroup spacing={2} size="sm">
                    <Button colorScheme="teal" onClick={() => handleGrade(rootQuestionIds, 1)}>채점하기</Button>
                    <Button colorScheme="orange" onClick={() => handleModify(rootQuestionIds, 2)}>오답 변형 문제</Button>
                </ButtonGroup></Th>
                <Th><ButtonGroup spacing={2} size="sm">
                    <Button colorScheme="teal" onClick={() => handleGrade(rootQuestionIds, 2)}>채점하기</Button>
                    <Button colorScheme="orange" onClick={() => handleModify(rootQuestionIds, 3)}>오답 변형 문제</Button>
                </ButtonGroup></Th>
                <Th><ButtonGroup spacing={2} size="sm">
                    <Button colorScheme="teal" onClick={() => handleGrade(rootQuestionIds, 3)}>채점하기</Button>
                    {/* <Button colorScheme="orange">오답 변형 문제</Button> */}
                </ButtonGroup></Th>
                </Tr>

              </Tfoot>
            </Table>
            
          </Box>
        </Grid>
      </Flex>
    </ChakraProvider>
  );
};