import React, { useState } from 'react';
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Text,
  useColorModeValue,
  ChakraProvider,
  Link as ChakraLink,
  useToast,
} from "@chakra-ui/react";
import { useNavigate } from 'react-router-dom';
import BgSignUp from "assets/img/BgSignUp.png";
import theme from "theme/theme.js";
import axios from 'axios';

function SignUp() {
  const titleColor = useColorModeValue("teal.300", "teal.200");
  const textColor = useColorModeValue("gray.700", "white");
  const bgColor = useColorModeValue("white", "gray.700");
  const navigate = useNavigate();
  const toast = useToast();

  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleLoginNavigate = () => {
    navigate('/signin');
  };

  const handleSignUp = async () => {
    setIsLoading(true);
    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_API_ENDPOINT}/user/signup/`, {
        username,
        email,
        password,
      });

      if (response.status === 201) {
        toast({
          title: "회원가입 성공",
          description: "성공적으로 회원가입이 완료되었습니다. 로그인 해주세요.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        console.log(response.data); // Assume token or other data
        window.localStorage.setItem("token", response.data.token);
        
        toast({
            description: "성공적으로 로그인 했습니다.",
            status: "success",
            duration: 5000,
            isClosable: true,
        });
        window.location.replace('/');      }
    } catch (error) {
      console.error('Failed to sign up', error);
      toast({
        title: "회원가입 실패",
        description: "회원가입에 실패했습니다. 다시 시도해주세요.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <ChakraProvider theme={theme} resetCss={false}>
      <Flex
        direction='column'
        alignSelf='center'
        justifySelf='center'
        overflow='hidden'>
        <Box
          position='absolute'
          minH={{ base: "70vh", md: "50vh" }}
          w={{ md: "calc(100vw - 50px)" }}
          borderRadius={{ md: "15px" }}
          left='0'
          right='0'
          bgRepeat='no-repeat'
          overflow='hidden'
          zIndex='-1'
          top='0'
          bgImage={BgSignUp}
          bgSize='cover'
          mx={{ md: "auto" }}
          mt={{ md: "14px" }}></Box>
        <Flex
          direction='column'
          textAlign='center'
          justifyContent='center'
          align='center'
          mt='6.5rem'
          mb='30px'>
          <Text fontSize='4xl' color='white' fontWeight='bold'>
            환영합니다.
          </Text>
          <Text
            fontSize='md'
            color='white'
            fontWeight='normal'
            mt='10px'
            mb='26px'
            w={{ base: "90%", sm: "60%", lg: "40%", xl: "30%" }}>
            지금 바로 계정을 생성하시고 맞춤형 문제를 제작해 보세요!
          </Text>
        </Flex>
        <Flex alignItems='center' justifyContent='center' mb='60px' mt='20px'>
          <Flex
            direction='column'
            w='445px'
            background='transparent'
            borderRadius='15px'
            p='40px'
            mx={{ base: "100px" }}
            bg={bgColor}
            boxShadow='0 20px 27px 0 rgb(0 0 0 / 5%)'>
            <Text
              fontSize='xl'
              color={textColor}
              fontWeight='bold'
              textAlign='center'
              mb='22px'>
              회원가입
            </Text>
            <FormControl>
              <FormLabel ms='4px' fontSize='sm' fontWeight='normal'>
                유저네임
              </FormLabel>
              <Input
                fontSize='sm'
                ms='4px'
                borderRadius='15px'
                type='text'
                placeholder='유저네임을 입력해주세요'
                mb='24px'
                size='lg'
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
              <FormLabel ms='4px' fontSize='sm' fontWeight='normal'>
                이메일
              </FormLabel>
              <Input
                fontSize='sm'
                ms='4px'
                borderRadius='15px'
                type='email'
                placeholder='이메일 주소를 입력해주세요'
                mb='24px'
                size='lg'
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <FormLabel ms='4px' fontSize='sm' fontWeight='normal'>
                비밀번호
              </FormLabel>
              <Input
                fontSize='sm'
                ms='4px'
                borderRadius='15px'
                type='password'
                placeholder='비밀번호를 입력해 주세요.'
                mb='24px'
                size='lg'
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <Button
                bg='teal.300'
                fontSize='10px'
                color='white'
                fontWeight='bold'
                w='100%'
                h='45'
                mb='24px'
                onClick={handleSignUp}
                isLoading={isLoading}
                _hover={{
                  bg: "teal.200",
                }}
                _active={{
                  bg: "teal.400",
                }}>
                회원가입
              </Button>
            </FormControl>
            <Flex
              flexDirection='column'
              justifyContent='center'
              alignItems='center'
              maxW='100%'
              mt='0px'>
              <Text color={textColor} fontWeight='medium'>
                이미 회원가입이 되어 있나요?
                <ChakraLink
                  color={titleColor}
                  as='span'
                  ms='5px'
                  onClick={handleLoginNavigate}
                  fontWeight='bold'
                  cursor='pointer'>
                  로그인 하기
                </ChakraLink>
              </Text>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </ChakraProvider>
  );
}

export default SignUp;
